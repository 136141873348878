<template>
    <div>
        <p>Comment List</p>
    </div>
</template>

<script>
    export default {
        name: 'v-comment-list',
        props: {
            // ...
        },
        data() {
            return {
                // ...
            }
        },
        created: function() {
            // ...
        },
        methods: {
            // ...
        }
    }
</script>

<style lang="scss" scoped>

</style>

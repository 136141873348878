<template>
    <div class="row">
        <div class="col">
            <!-- Create a new tweet. -->
            <div class="tw-bg-white tw-rounded-lg tw-shadow tw-mb-8">
                <div class="tw-px-6 tw-py-5 tw-border-b tw-border-gray-800">
                    <div class="d-flex w-100">
                        <div class="flex-shrink-0 me-4">
                            <div class="d-flex flex-column h-100">
                                <div class="tw-cursor-pointer tw-font-bold tw-w-12 tw-h-12 tw-bg-gray-300 d-flex align-items-center justify-content-center rounded-circle">
                                    <span class="tw-uppercase tw-text-gray-700">JD</span>
                                </div>
                                <!-- <div class="flex-grow-1 d-none" style="margin: 10px 20px -10px 20px;border-right: 2px solid #ccc;"></div> -->
                            </div>
                        </div>
                        <div class="flex-shrink-1 flex-grow-1 flex-basis-0">
                            <div class="d-flex justify-content-between mb-3">
                                <textarea rows="3" class="form-control" placeholder="Type your comment..."></textarea>
                            </div>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-block btn-primary">Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'v-comment-create',
        props: {
            // ...
        },
        data() {
            return {
                // ...
            }
        },
        created: function() {
            // ...
        },
        methods: {
            // ...
        }
    }
</script>

<style lang="scss" scoped>

</style>

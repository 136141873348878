import { render, staticRenderFns } from "./Commander.vue?vue&type=template&id=462bf9be&scoped=true&"
import script from "./Commander.vue?vue&type=script&lang=js&"
export * from "./Commander.vue?vue&type=script&lang=js&"
import style0 from "./Commander.vue?vue&type=style&index=0&id=462bf9be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462bf9be",
  null
  
)

export default component.exports
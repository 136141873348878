window._ = require('lodash');

window.$ = window.jQuery = require('jquery');

window.Popper = require('@popperjs/core').default;

require('bootstrap');

window.feather = require('feather-icons');

window.PerfectScrollbar = require('perfect-scrollbar').default;

require('./circl.js');

var router = require('./router.js');
window.route = router.route;

// window.dayjs = require('dayjs');
// var utc = require('dayjs/plugin/utc');
// window.dayjs.extend(utc);
// var timezone = require('dayjs/plugin/timezone');
// window.dayjs.extend(timezone);

const {searchParam, dumpJson, dumpJsonStr} = require('./utils.js');
window.searchParam = searchParam;
window.dumpJson = dumpJson;
window.dumpJsonStr = dumpJsonStr;

window.toastr = require('toastr');

// Ajax

window.$.ajaxSetup({
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// Axios

window.axios = require('axios');

window.axios.defaults.withCredentials = true;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

import Form from './forms/form.js';
window.Form = Form;

// VueJS

window.Vue = require('vue');

Vue.component('artisan-gui-commander', require('./components/artisan-gui/Commander.vue').default);

Vue.component('v-comment-back', require('./components/comments/Back.vue').default);
Vue.component('v-comment-create', require('./components/comments/Create.vue').default);
Vue.component('v-comment-current', require('./components/comments/Current.vue').default);
Vue.component('v-comment-feed', require('./components/comments/Feed.vue').default);
Vue.component('v-comment-list', require('./components/comments/List.vue').default);
Vue.component('v-comment-replies', require('./components/comments/Replies.vue').default);
Vue.component('v-comment-reply', require('./components/comments/Reply.vue').default);
Vue.component('v-comment-single', require('./components/comments/Single.vue').default);

Vue.component('v-task-documents', require('./components/tasks/Documents.vue').default);

Vue.component('v-users-list', require('./components/users/List.vue').default);

import VueFeather from 'vue-feather';
Vue.component('vue-feather', VueFeather);

// https://medium.com/dailyjs/casl-and-cancan-permissions-sharing-between-ui-and-api-5f1fa8b4bec
// https://mmccaff.github.io/2018/11/03/laravel-permissions-in-vue-components
// https://www.youtube.com/watch?v=uYSkmjGf8Y0
import Auth from './mixins/Auth';
Vue.mixin(Auth);

const app = new Vue({
    el: '#app',
});

<template>
    <div class="row">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <select class="form-control" id="role_id" name="role_id">
                        <option>Select Role...</option>
                    </select>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <select class="form-control" id="revoked" name="revoked">
                        <option value="" selected>With Revoked</option>
                        <option value="only" >Only Revoked</option>
                        <option value="without">Without Revoked</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <div class="flex-grow-1 me-2">
                            <input type="search" class="form-control" v-model="search"
                                placeholder="Search by Name, Alias, or Email..." v-on:keyup.enter="fetchRecords()"/>
                        </div>
                        <!-- <div class="d-flex align-items-center">
                            <span>Showing</span>
                            <select class="form-control p-2 mx-2" v-model="per_page" v-on:click="fetchRecords()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span>entries</span>
                        </div> -->
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped my-4">
                            <thead>
                                <tr>
                                    <th v-for="(field, index) in visibleFields" v-bind:key="index"
                                        v-on:click="sortBy(field)"
                                        v-bind:class="{
                                            'sort': field.sortable && isNotSorted(field.name),
                                            'sort-asc': isSortedAsc(field.name),
                                            'sort-desc': isSortedDesc(field.name)
                                        }">
                                        {{ field.label }}
                                    </th>
                                    <!-- <th class="d-flex justify-content-between cp"
                                        v-for="(field, index) in visibleFields" v-bind:key="index">
                                        <span>{{ field.label }}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #D1D5DB;transform: ;msFilter:;">
                                            <path d="m6.293 13.293 1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z"></path>
                                        </svg>
                                    </th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in data" v-bind:key="index">
                                    <td v-for="(value, key) in row" v-bind:key="key" v-if="getField(key).visible">
                                        {{ value }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between align-items-center">
                        <div>
                            Showing <span v-html="from"></span>
                            to <span v-html="to"></span>
                            of <span v-html="total"></span>
                            <span v-if="isFiltered">matching</span> entries.
                        </div>
                        <div class="d-flex align-items-center">
                            <span>Showing</span>
                            <select class="form-control p-2 mx-2" v-model="per_page" v-on:click="fetchRecords()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span>entries</span>
                        </div>
                        <div class="d-flex justify-content-end">
                            <ul class="pagination my-0">
                                <li class="paginate_button page-item"
                                    v-for="(link, index) in links" v-bind:key="index"
                                    v-bind:class="{
                                        active: link.active,
                                        disabled: ! link.url,
                                        previous: link.label == '&laquo; Previous',
                                        next: link.label == 'Next &raquo;'
                                    }">
                                    <span class="page-link cp"
                                        v-html="link.label"
                                        v-on:click.prevent="loadPageNumber(link.url)"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'v-users-list',
        data() {
            return {
                // Client Request .............
                fields: [
                    {table: 'users', name: 'id', label: '#', visible: false, sortable: false},
                    {table: 'users', name: 'name', label: 'Name', visible: true, sortable: true},
                    {table: 'users', name: 'alias', label: 'Alias', visible: true, sortable: false},
                    {table: 'users', name: 'email', label: 'Email Address', visible: true, sortable: true},
                    {table: 'users', name: 'role_id', label: 'UserRoleId', visible: false, sortable: true},
                    {table: 'role', name: 'id', label: 'RoleId', visible: true, sortable: true},
                    {table: 'role', name: 'name', label: 'RoleName', visible: true, sortable: true},
                ],
                search: '',
                sort: 'name',
                // API Response .............
                data: [],
                per_page: 10,
                current_page: 1,
                last_page: 0,
                from: 0,
                to: 0,
                total: 0,
                links: [],
                path: '',
                first_page_url: '',
                prev_page_url: '',
                next_page_url: '',
                last_page_url: '',
            }
        },
        created: function() {
            this.fetchRecords();
        },
        computed: {
            visibleFields: function() {
                return this.fields.filter(function (field) {
                    return field.visible;
                });
            },
            isFiltered: function() {
                return this.first_page_url.includes('filter%5Bsearch%5D');
            }
        },
        methods: {
            getField: function(fieldName) {
                return this.fields.find(function(field, index) {
                    if(field.name == fieldName)
                        return true;
                });
            },
            isNotSorted: function(fieldName) {
                var regex = new RegExp(`sort=-?${fieldName}`);

                return this.first_page_url.search(regex) === -1;
            },
            isSortedAsc: function(fieldName) {
                return this.first_page_url.includes(`sort=${fieldName}`);
            },
            isSortedDesc: function(fieldName) {
                return this.first_page_url.includes(`sort=-${fieldName}`);
            },
            sortBy: function(field) {
                if(! field.sortable) {
                    return;
                }

                var dir = this.first_page_url.includes(`sort=${field.name}`) ? '-' : '';

                // console.log(`sort=${dir}${field.name}`);

                this.sort = `${dir}${field.name}`;

                this.fetchRecords();
            },
            loadPageNumber: function(linkUrl) {
                var url = new URL(linkUrl);

                this.current_page = url.searchParams.get('page[number]');

                this.fetchRecords();
            },
            fetchRecords: function() {
                let app = this;

                var params = {
                    // 'include': 'role',
                    'filter[search]': app.search,
                    'page[size]': app.per_page,
                    'page[number]': app.current_page,
                    'sort': app.sort,
                };

                // ...........

                var fieldsByTable = _.groupBy(app.fields, 'table');

                for (const [tableName, properties] of Object.entries(fieldsByTable)) {
                    var columnNames = properties.map((property) => property.name);
                    params[`fields[${tableName}]`] = columnNames.join(',');
                }

                // ...........

                axios
                    .get(route('api.users.index', params))
                    .then(function (response) {
                        // dumpJson(response.data);

                        app.data = response.data.data;
                        app.per_page = response.data.per_page;
                        app.current_page = response.data.current_page;
                        app.last_page = response.data.last_page;
                        app.from = response.data.from;
                        app.to = response.data.to;
                        app.total = response.data.total;
                        app.links = response.data.links;
                        app.path = response.data.path;
                        app.first_page_url = response.data.first_page_url;
                        app.prev_page_url = response.data.prev_page_url;
                        app.next_page_url = response.data.next_page_url;
                        app.last_page_url = response.data.last_page_url;
                    })
                    .catch(function (error) {
                        dumpJson(error.response.data);
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cp {
        cursor: pointer;
    }

    table {
        thead {
            th {
                font-weight: 500;
                text-transform: uppercase;
            }
            .sort {
                cursor: pointer;
                position: relative;
                &:before {
                    position: absolute;
                    right: 7px;
                    content: "\21BF";
                    opacity: 0.35;
                }
                &:after {
                    position: absolute;
                    right: 2px;
                    content: "\21C2";
                    opacity: 0.35;
                }
            }
            .sort-asc {
                cursor: pointer;
                position: relative;
                &:before {
                    position: absolute;
                    right: 7px;
                    content: "\21BF";
                    opacity: 1;
                }
                &:after {
                    position: absolute;
                    right: 2px;
                    content: "\21C2";
                    opacity: 0.35;
                }
            }
            .sort-desc {
                cursor: pointer;
                position: relative;
                &:before {
                    position: absolute;
                    right: 7px;
                    content: "\21BF";
                    opacity: 0.35;
                }
                &:after {
                    position: absolute;
                    right: 2px;
                    content: "\21C2";
                    opacity: 1;
                }
            }
        }
    }

</style>

<template>
    <div class="row">
        <div class="col">
            <div class="tw-bg-white tw-rounded-lg tw-shadow tw-mb-8">
                <div class="tw-px-6 tw-py-5 tw-border-b tw-border-gray-800 tw-cursor-pointer" v-on:click="selectComment">
                    <div class="d-flex w-100">
                        <div class="flex-shrink-0 me-4">
                            <div class="d-flex flex-column h-100">
                                <div class="tw-cursor-pointer tw-font-bold tw-w-12 tw-h-12 tw-bg-gray-300 d-flex align-items-center justify-content-center rounded-circle">
                                    <span class="tw-uppercase tw-text-gray-700">JD</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-shrink-1 flex-grow-1 flex-basis-0">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex">
                                    <div v-if="comment.author">
                                        <a href="#" class="tw-font-bold tw-text-gray-500 me-1">{{ comment.author.name }}</a>
                                        <a href="#" class="tw-text-gray-500 me-1">@{{ comment.author.alias }}</a>
                                        <span class="me-1">•</span>
                                    </div>
                                    <span class="text-muted" v-else>Deleted User</span>
                                    <a href="#" class="tw-text-gray-500">{{ comment.created_at | diffForHumans }}</a>
                                    <!-- <span class="ms-1">#{{ comment.id }}</span> -->
                                </div>
                                <svg class="tw-h-6 tw-w-6 tw-cursor-pointer" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                                </svg>
                            </div>
                            <div class="mt-1 mb-2">
                                <div v-html="comment.body"></div>
                            </div>
                            <div class="d-flex align-items-center w-100">
                                <div class="w-25 d-flex align-items-center">
                                    <div class="tw-cursor-pointer d-inline-flex rounded-circle">
                                        <svg class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="ms-2 tw-leading-none d-inline-flex">{{ comment.comments.length }} replies</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import relativeTime from 'dayjs/plugin/relativeTime';

    export default {
        name: 'v-comment-single',
        props: {
            comment: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                // ...
            }
        },
        created: function() {
            // dumpJson(this.comment);
            dayjs.extend(relativeTime);
        },
        filters: {
            diffForHumans: (date) => {
                if (! date) {
                    return null;
                }

                return dayjs(date).fromNow();
            }
        },
        methods: {
            selectComment: function() {
                this.$emit('select-comment', this.comment);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <!-- https://codepen.io/mithicher/pen/JjdgYdy -->
    <div>
        <div v-if="! this.comment">
            <v-comment-create />
            <!-- <v-comment-list /> -->
            <v-comment-single
                v-for="(comment, index) in this.comments" v-bind:key="comment.id"
                v-bind:comment="comment"
                v-on:select-comment="showReplies" />
        </div>
        <div v-else>
            <!-- <v-comment-back /> -->
            <div class="tw-bg-white tw-rounded-lg tw-shadow tw-mb-8" v-on:click="showParentComment(comment)">
                <div class="tw-px-6 tw-py-5 tw-border-b tw-border-gray-800">
                    <div class="d-flex w-100 tw-cursor-pointer">
                        <svg class="tw-h-6 tw-w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <line x1="19" y1="12" x2="5" y2="12"></line>
                            <polyline points="12 19 5 12 12 5"></polyline>
                        </svg>
                        <h5 class="ms-3 mb-0">Comment</h5>
                        <!-- <span class="ms-2">{{ this.comment.commentable_id }} <- {{ this.comment.id }}</span> -->
                    </div>
                </div>
            </div>
            <!--
                Current tweet:
                - not clickable
                - cursor: text
            -->
            <v-comment-single v-bind:comment="this.comment" />

            <v-comment-reply v-bind:comment="this.comment" />

            <v-comment-single
                v-for="(comment, index) in this.comment.comments" v-bind:key="comment.id"
                v-bind:comment="comment"
                v-on:select-comment="showReplies" />
        </div>
    </div>
</template>

<script>
    import CommentList from './List';
    import CreateComment from './Create';
    import CommentBack from './Back';

    export default {
        name: 'v-comment-feed',
        components: {
            'v-comment-list': CommentList,
            'v-comment-create': CreateComment,
            'v-comment-back': CommentBack,
        },
        props: {
            commentableId: {
                type: Number,
                required: true
            },
            commentableType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showingReplies: false,
                comment: null,
                comments: [] // comments/replies to commentable
            }
        },
        created: function() {
            this.fetchComments();
        },
        methods: {
            fetchComments: function() {
                let app = this;

                axios
                    .get(route('api.comments.index', {
                        'includes[0]': 'author',
                        'includes[1]': 'comments',
                        'commentable_id': app.commentableId,
                        'commentable_type': app.commentableType
                    }))
                    .then(function (response) {
                        // dumpJson(response.data);

                        app.comments = response.data.comments;
                    })
                    .catch(function (error) {
                        dumpJson(error.response.data);
                    });
            },
            showReplies: function(comment) {
                console.log(`showReplies: ${comment.commentable_id} -> ${comment.id}`);

                let app = this;

                axios
                    .get(route('api.comments.index', {
                        'includes[0]': 'author',
                        'includes[1]': 'comments',
                        'commentable_id': comment.id,
                        'commentable_type': 'comment',
                    }))
                    .then(function (response) {
                        comment.comments = response.data.comments;

                        app.comment = comment;

                        // dumpJson({'this.comment': app.comment});
                    })
                    .catch(function (error) {
                        dumpJson(error.response.data);
                    });
            },
            showParentComment: function(comment) {
                console.log(`showParentComment: ${comment.commentable_id} <- ${comment.id}`);

                if(comment.commentable_type == 'task') {
                    this.comment = null;
                    return;
                }

                let app = this;

                axios
                    .get(route('api.comments.show', {
                        'comment': comment.commentable_id,
                        'includes[0]': 'author',
                        'includes[1]': 'comments',
                    }))
                    .then(function (response) {
                        // dumpJson(response.data);

                        app.comment = response.data;
                    })
                    .catch(function (error) {
                        dumpJson(error.response.data);
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>

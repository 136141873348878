exports.searchParam = function(key, value = '', location = window.location)
{
    if (! key) {
        throw new Error('The key arg is required.');
    }

    var searchParams = new URLSearchParams(location.search);

    searchParams.set(key, value);

    return location.origin+location.pathname+'?'+searchParams.toString();
}

exports.dumpJson = function(json) {
  console.log(JSON.parse(JSON.stringify(json)));
}

exports.dumpJsonStr = function(json) {
  console.log(JSON.stringify(json));
}

// https://stackoverflow.com/a/39847253/2732184
if (! String.prototype.toSlug) {
    Object.defineProperty(String.prototype, 'toSlug', {
        value: function() {
            return this
                .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
                .toLowerCase()
                .replace(/^\s+|\s+$/gm, '')
                .replace(/\s+/g, '-');
        }
    });
}
